<template>
  <div id='base'>
    <div class="products-menu" v-show="isProductMenushow" id='products-menu'>
      <div v-for="(value, index) in dataList['productList']" :key="index" class="flex-center product-menu-item" @click="onMenuItemClick(index)">
        {{value.title}}
      </div>
    </div>
    <div class="header-container">
      <div class="header">
        <div class="logo-container">
          <el-image class="logo" :src="dataList['logo']"></el-image>
          <div class="logo-text-container header-logo-text-container">
            <div class="logo-text-name">{{dataList.logoName}}</div>
            <div class="logo-text-slogan">{{dataList.slogan}}</div>
          </div>
        </div>

        <el-tabs v-model="currentPage" @tab-click="onBtnPageClick" class="menu">
          <el-tab-pane label="首页" name="home"></el-tab-pane>
          <el-tab-pane label="产品" name="products"></el-tab-pane>
          <el-tab-pane label="资讯" name="consult"></el-tab-pane>
          <el-tab-pane label="关于" name="about"></el-tab-pane>
        </el-tabs>
      </div>
    </div>


    <router-view @changeTabs="changeTabs" :key="$route.fullPath"></router-view>

    <div class="footer">
      <div class="flex-center footer-up">
        <div class="footer-up-content-container footer-up-content-container-fix">
          <div class="footer-up-logo-group footer-up-logo-group-fix">
            <el-image class="logo" :src="dataList['logo']"></el-image>
            <div class="logo-text-container footer-logo-text-container">
              <div class="logo-text-name">{{dataList.logoName}}</div>
              <div class="logo-text-slogan">{{dataList.slogan}}</div>
            </div>
          </div>

          <div class="footer-contact">
            <div class="contact-title">{{dataList.footer.contact}}</div>
            <div class="address">{{dataList.footer.address}}</div>
            <div class="mail">{{dataList.footer.mail}}</div>
          </div>

          <div class="footer-up-seperator"></div>
        </div>
      </div>

      <div class="copyright">
        {{dataList.footer.copyright}}
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'App',
  components: {
    
  },

  created(){
    axios.get("/json/footer.json")
    .then(res => {
      this.dataList["footer"] = res.data;
    })

    axios.get("/json/home.json")
    .then(res => {
      this.dataList["productList"] = res.data["productList"];
      this.dataList["logo"] = res.data["logo"];
      this.dataList["logoName"] = res.data["logoName"];
      this.dataList["slogan"] = res.data["slogan"];
    })
  },

  mounted(){
    this.$nextTick(() => {
      var tabProducts = document.getElementById("tab-products");
      var rect = tabProducts.getBoundingClientRect();
      
      var productMenu = document.getElementById("products-menu");
      productMenu.style.left = rect.left + 'px';
      productMenu.style.top = (rect.bottom - 2) + 'px';

      let me = this;
      tabProducts.addEventListener('mouseover', function(event) {
        me.currentPage = 'products';
        me.isProductMenushow = true;
        event.stopPropagation();
      })

      productMenu.addEventListener('mouseover', function(event) {
        me.isProductMenushow = true;
        event.stopPropagation();
      })

      var base = document.getElementById('base');
      base.addEventListener('mouseover', function(){
        me.isProductMenushow = false;
      })
    })
  },

  data(){
    return {
      currentPage: "home",
      dataList: {
        footer: {}
      },

      mouseX: 0,
      mouseY: 0,
      isProductMenushow: false
    }
  },

  methods: {
    onBtnPageClick(page){
      if(page.paneName == "products"){
        return;
      } else {
        this.$router.push(page.paneName);
      }
    },

    onMenuItemClick(index){
      this.$router.push({
        path: '/product',
        query: {
          name: this.dataList["productList"][index]["name"]
        }
      });
      this.isProductMenushow = false;
    },

    changeTabs(name){
      this.currentPage = name;
    }
  }
}
</script>

<style>

html{
  font-size: 1px;
}

@media screen and (max-width: 720px){
  html{
    font-size: 0.9px;
  }
}

@media screen and (max-width: 500px){
  html{
    font-size: 0.88px;
  }
}

@media screen and (max-width: 300px){
  html{
    font-size: 0.86px;
  }
}

body{
  margin: 0;
  padding: 0;
}

.product-menu-item:hover{
  color: rgba(33, 160, 252, 1);
}

.header-container{
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  margin-top: 20px;
  z-index: 1;
}

.header{
  width: 62%;
  display: flex; 
  align-items: center;
  flex-direction: row; 
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo{
  width: 50px;
  height: 50px;
}

.logo-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-logo-text-container{
  color: black;
}

.logo-text-container{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.logo-text-name{
  font-size: 15rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 2px;
}

.logo-text-slogan{
  font-size: 9rem;
  font-weight: 400;
  line-height: 13px;
  margin-top: 5px;
}

.menu{
  /*margin-left: 412px;*/
}

.contact-title{
  font-size: 18rem;
}

.address{
  font-size: 12rem;
  margin-top: 10px;
  /*width: 500px;*/
}

.phone{
  font-size: 12rem;
  margin-top: 10px;
}

.mail{
  font-size: 12rem;
  margin-top: 10px;
}

.copyright{
  width: 100vw; 
  height: 20%; 
  display: flex; 
  flex-wrap: wrap;
  justify-content: center; 
  align-items: center; 
  color: white; 
  font-size: 12rem;
}

.products-menu{
  position: absolute; 
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.90);
  border-radius: 8px;
}

.product-menu-item{
  height: 48px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14rem;
  color: rgba(51, 51, 51, 1);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
}

.footer{
  width: 100%;
  height: 368px;
  background-color: rgba(20, 40, 71, 1);
  bottom: 0;
}

.footer-logo-text-container{
  color: white;
}

.footer-up{
  width: 100%; 
  height: 80%; 
  position: relative;
}

.footer-up-content-container{
  width: 70.1%;
  /*display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footer-up-logo-group{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: start;
}

.footer-up-seperator{
  width: calc(1292 / 1440 * 100%); 
  background-color: rgba(255, 255, 255, 0.13); 
  height: 1px; 
  left: calc(95 / 1440 * 100%);
  bottom: 0; 
  position: absolute;
}

.footer-contact{
  color: white;
  justify-self: end;
}

@media screen and (max-width: 532px){
  .footer-up-content-container-fix{
    grid-template-columns: repeat(1, 1fr);
  }

  .footer-up-logo-group-fix{
    justify-self: center;
  }
}

</style>

