<template>
	<div class="base">
		<banner :bannerBackground="dataList.banner" :title="dataList.title" :title1="dataList.title1" :title3="dataList.title3">
		</banner>

		<div class="flex-center xyz-title consult-chinese-title">
			{{dataList.ChineseTitle}}
		</div>

		<div class="flex-center">
			<div class="consult-english-title consult-english-title-fix">
				{{dataList.EnglishTitle}}
			</div>
		</div>

		<div class="consult-item-container">
			<div class="consult-item consult-item-fix" v-for="(value, index) in dataList.list" :key="index">
				<div class="consult-item-title">
					{{value.title}}
				</div>

				<div class="xyz-desc consult-item-desc">
					{{value.desc}}
				</div>

				<div class="consult-item-chakan-container">
					<div class="consult-item-shijian-container">
						<el-image :src="dataList.imgClock" class="consult-item-clock"></el-image>
						<div class="xyz-desc">
							{{value.date}}
						</div>
					</div>

					<el-button class="consult-item-chakan xyz-button" @click="onBtnChakanClick(value.id)">
						查看详情
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

import banner from "@/components/BannerComponent.vue"
import "@/css/Banner.css"
import axios from 'axios'

export default{
	components: {
		banner
	},

	created(){
		this.$emit('changeTabs', 'consult');

		axios.get("/json/consult.json")
		.then(res => {
			this.dataList = res.data;
		})
	},

	data(){
		return {
			dataList: {},
		}
	},

	methods: {
		onBtnChakanClick(id){
			this.$router.push({
				path: "/consultdetail",
				query: {
					id: id
				}
			})
		}
	}
}
	
</script>

<style type="text/css" scoped>

.consult-english-title-fix{
	margin-top: 6px;
	font-size: 24rem;
	color: rgba(124, 128, 133, 1);
	line-height: 34px;
}

.consult-item-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 51px;
}

.consult-item-line{
	display: flex;
	flex-direction: row;
	width: 894px;
}

.consult-item{
	width: calc(900 / 1440 * 100vw);
	height: 173px;
	margin-bottom: 27px;
	background-color: white;
	box-shadow: 0px 2px 38px 0px rgba(199,211,243,0.34);
	border-radius: 8px;
	padding-top: 35px;
	padding-left: 35px;
	padding-right: 40px;
	box-sizing: border-box;
	position: relative;
}

@media screen and (max-width: 484px){
	.consult-item-fix{
		width: calc(0.9 * 90vw);
	}
}

.consult-item-title{
	width: 100%;
	height: 21px;
	font-size: 15rem;
	color: rgba(7, 21, 33, 1);
	font-weight: 600;
	line-height: 21px;
	overflow: hidden;
}

.consult-item-desc{
	width: 100%;
	height: 36px;
	margin-top: 15px;
	overflow: hidden;
	position: relative;
	font-size: 11rem;
	font-weight: 400;
	line-height: 18px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}

.consult-item-clock{
	width: 12px;
	height: 12px;
	margin-right: 8px;
}

.consult-item-shijian-container{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.consult-item-chakan{
	width: 72px;
	height: 27px;
	font-size: 11rem;
	background-color: rgba(219, 240, 255, 1);
	color: rgba(33, 160, 252, 1);
}

.consult-item-chakan-container{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
}

	
</style>