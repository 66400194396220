<template>
	<div class="base">
		<banner :bannerBackground="dataList.banner" :title="dataList.title" :title1="dataList.title1">
		</banner>

		<div class="flex-center xyz-title about-chinese-title-fix">
			{{dataList.ChineseTitle}}
		</div>

		<div class="flex-center">
			<div class="about-english-title about-english-title-fix">
				{{dataList.EnglishTitle}}
			</div>
		</div>

		<div class="flex-center">
			<div class="about-desc xyz-desc">
				在科技的浪潮中，我们不仅关注当下，更着眼于未来。始终保持敏锐的洞察力，紧跟科技不断创新，为社会创造更多的价值。
			</div>
		</div>

		<div class="advantage-container">
			<div class="advantage-title xyz-title">
				我们的优势
			</div>

			<div class="advantage-inner1-container">
				<div v-for="(value, index) in dataList.advantageList" :key="index" class="advantage-item">
					<div class="xyz-title advantage-item-title">
						{{value.title}}
					</div>

					<div class="xyz-desc advantage-item-desc">
						{{value.desc}}
					</div>

					<el-image :src="value.image" class="advantage-item-image"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import banner from "@/components/BannerComponent.vue"
import "@/css/Banner.css"
import axios from 'axios'

export default{
	components: {
		banner
	},

	data(){
		return {
			dataList: {}
		}
	},

	created(){
		this.$emit('changeTabs', 'about');

		axios.get("/json/about.json")
		.then(res => {
			this.dataList = res.data;
		})
	}
}
	
</script>

<style type="text/css" scoped>

.about-chinese-title-fix{
	font-size: 38rem;
  font-weight: 500;
}

.about-english-title-fix{
	margin-top: 6px;
	font-size: 24rem;
	color: rgba(124, 128, 133, 1);
	line-height: 34px;
}

.about-desc{
	margin-top: 29px;
	font-size: 12rem;
	color: rgba(56, 60, 64, 1);
	width: 90vw;
	text-align: center;
}

.advantage-container{
	background: linear-gradient( 180deg, #EFF3FF 0%, #FFFFFF 100%);
	width: 100%;
	/*height: 681px;*/
	margin-top: 91px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.advantage-title{
	font-size: 38rem;
	margin-top: 65px;
}

.advantage-inner1-container{
	width: 100%;
	margin-top: 66px;
	overflow-x: auto;
	overflow-y: hidden;
	text-align: center;
	overflow: visible;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 28px;
	margin-bottom: 212px;
}

.advantage-item{
	height: 287px;
	width: 280px;
	/*margin-left: 14px;*/
	/*margin-right: 14px;*/
	background: rgba(255,255,255,0.2);
	box-shadow: 5px 11px 38px 0px rgba(179,198,232,0.59), inset 0px 2px 3px 0px #FFFFFF, inset 0px 4px 4px 0px rgba(255,255,255,0.5);
	border-radius: 23px;
	filter: blur(0px);
	flex-shrink: 0;
	padding-top: 35px;
	padding-left: 24px;
	box-sizing: border-box;
	position: relative;
	text-align: left;
	overflow: hidden;
}

.advantage-item-title{
	font-size: 17rem;
	font-weight: 600;
}

.advantage-item-desc{
	margin-top: 9px;
	width: 222px;
	overflow: hidden;
}

.advantage-item-image{
	position: absolute;
	right: 0;
	bottom: 0;
	width: 201px;
	height: 168px;
}
	
</style>