<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" style="position: relative;">
    <el-tab-pane label="用户管理" name="first">用户管理的内容</el-tab-pane>
    <el-tab-pane label="配置管理" name="second">配置管理的内容</el-tab-pane>
    <el-tab-pane label="角色管理" name="third">角色管理的内容</el-tab-pane>

    <div style="width: 50px; height: 50px; background-color: yellow; position: absolute; top: 0; left: 0; z-index: 100;"></div>
  </el-tabs>
</template>
 
<script setup>
</script>