import { createRouter, createWebHashHistory } from 'vue-router'
import home from '@/components/HomePage.vue'
import product from '@/components/ProductPage.vue'
import consult from '@/components/ConsultPage.vue'
import consultDetail from '@/components/ConsultDetail.vue'
import about from '@/components/AboutPage.vue'
import hello from '@/components/HelloWorld.vue'


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: home,
    name: "home",
  },
  {
    path: '/product',
    component: product,
    name: 'product'
  },
  {
    path: '/consult',
    component: consult,
    name: 'consult'
  },
  {
    path: '/consultdetail',
    component: consultDetail,
    name: 'consultdetail'
  },
  {
    path: '/about',
    component: about,
    name: 'about'
  },
  {
    path: '/hello',
    component: hello,
    name: 'hello'
  }
]

export default createRouter({
	history: createWebHashHistory(),
	routes,
})