<template>
	<div class="base">
		<banner :bannerBackground="dataList.banner" :title="dataList.title" :title1="dataList.title1" :title3="dataList.title3">
			<div>dfdsfffdsfdsf</div>
		</banner>

		<div class="flex-center xyz-title product-title">
			{{dataList.productTitle}}
		</div>

		<div class="flex-center">
			<div class="product-detail">
				{{dataList.productDetail}}
			</div>
		</div>

		<div class="product-item-outer-container product-item-outer-container-fix">
			<div class="product-item-container product-item-container-fix">
				<div class="product-item product-item-fix" :style="{'--bg-image': value.background}" v-for="(value, index) in dataList.productList" :key="index">
					<div class="title-desc-container">
						<div class="xyz-title product-item-title">{{value.title}}</div>
						<div class="xyz-desc product-item-desc">{{value.desc}}</div>
						<div @click="onBtnProductClick(value.name)" class="xyz-desc product-item-more">MORE ></div>
					</div>
				</div>
			</div>
		</div>

		<div class="dynamics-container">
			<div class="dynamics dynamics-fix">
				<div class="dynamics-left dynamics-left-fix">
					<div class="xyz-title dynamics-left-title">{{dataList.dynamics.current.title}}</div>

					<div class="dynamics-left-image-container">
						<el-image :src="dataList.dynamics.current.image" class="dynamics-left-image"></el-image>
					</div>

					<div class="xyz-title dynamics-left-current-title" style="margin-top: 20px;">
						{{dataList.dynamics.current.title1}}
					</div>
					
					<div class="xyz-desc dynamics-left-current-desc">
						{{dataList.dynamics.current.content}}
					</div>

					<div class="dynamics-left-current-more-container">
						<div class="xyz-desc dynamics-left-current-more" @click="onBtnConsultClick(dataList.dynamics.current.id)">
							MORE >
						</div>
					</div> 
				</div>

				<div class="dynamics-right dynamics-right-fix">
					<div v-for="(value, index) in dataList.dynamics.history" :key="index" class="dynamics-right-history-item" @click="onBtnConsultClick(value.id)">
						<div class="dynamics-right-history-item-datetime-container">
							<div class="xyz-title dynamics-right-history-item-monthday">
								{{value.monthday}}
							</div>
							<div class="xyz-desc dynamics-right-history-item-year">
								{{value.year}}
							</div>
						</div>

						<div class="xyz-vertical-seperator dynamics-right-seperator"></div>

						<div class="dynamics-right-history-item-titledesc-container">
							<div class="xyz-title dynamics-right-history-item-title">{{value.title}}</div>
							<div class="xyz-desc dynamics-right-history-item-desc" style="margin-top: 5px">{{value.content}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script type="text/javascript">

import banner from "@/components/BannerComponent.vue"
import "@/css/Banner.css"
import axios from "axios"

export default{
	components: {
		banner
	},

	created(){
		axios.get("/json/home.json")
		.then(res => {

			this.dataList = res.data;
		})
		.catch(error => {
			console.log(error);
		})
	},

	data(){
		return {
			dataList: {
				productList: [],
				dynamics: {
					current: {},
					history: []
				}
			}
		}
	},

	methods: {
		onBtnProductClick(name){
			this.$router.push({
				path: "/product",
				query: {
					name: name
				}
			});

			this.$emit('changeTabs', 'produ');
		},

		onBtnConsultClick(id){
			this.$router.push({
				path: "/consultdetail",
				query: {
					id: id
				}
			})
		}
	}
}
</script>

<style type="text/css" scoped>

.product-item-outer-container{
	display: flex;
	justify-content: center;
	margin-top: 48px;
	margin-bottom: 80px;
}

.product-item-container{
	/*width: calc(438 / 1440 * 100vw * 2 + 30px); 
	height: calc(540 / 1440 * 100vw);*/
	display: flex;
	flex-direction: row;
}

.product-item{
	width: calc((438 / 1440) * 100vw);
	height: calc((540 / 1440) * 100vw);
	min-width: 197.7px;
	min-height: 243.75px;
	margin-right: 30px;
	/*height: 100%; */
	background-repeat: no-repeat;
	background-size: cover;
	flex-shrink: 0;
	text-align: left;
	box-sizing: border-box;
	background-image: var(--bg-image);
	/*background-color: purple;*/
}

@media screen and (max-width: 640px){
	.product-item-container{
		flex-direction: column;
	}

	.product-item-fix{
		margin-right: 0;
		margin-bottom: 30px;
		width: calc((438 / 1440 * 2) * 100vw);
		height: calc((540 / 1440 * 2) * 100vw);
	}

	.product-item-outer-container-fix{
		margin-bottom: 50px;
	}
}

.title-desc-container{
	margin-top: 8.3%;
	margin-left: 12.1%;
}

.product-item-title{
	font-weight: 400;
	font-size: 22rem;
}

.product-item-desc{
	margin-top: 14px;
	line-height: 19px;
	width: 69.2%;
	max-height: 150px;
	overflow: hidden;
	font-family: PingFangSC, PingFang SC;
}

.product-item-more{
	margin-top: 16px;
	cursor: pointer;
	display: inline-flex;
}

.product-item-more:hover{
	color: rgba(7, 126, 231, 1);
}

.dynamics-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(249, 251, 255, 1);
}

.dynamics{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding-bottom: 136px;
	box-sizing: border-box;
	width: 62.5%;
}

.dynamics-left{
	display: flex;
	flex-direction: column;
	width: calc(438 / 1440 * 100vw);
}

.dynamics-left-image{
	width: 100%;
	height: 100%;
	overflow: initial;
}

.dynamics-left-image-container{
	border-radius: 20px;
	overflow: hidden;
	background-color: #e5ecff;
	margin-top: 30px;
}

.dynamics-left-title{
	font-size: 24rem;
	font-weight: 600;
	margin-top: 51px;
}

.dynamics-left-current-title{
	font-size: 14rem;
	font-weight: 600px;
	margin-top: 23px;
}

.dynamics-left-current-desc{
	margin-top: 12px;
	width: 100%;
	/*height: 36px;*/
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
}

.dynamics-left-current-more{
	margin-top: 17px;
	cursor: pointer;
}

.dynamics-left-current-more-container{
	display: inline-flex;
}

.dynamics-left-current-more:hover{
	color: rgba(7, 126, 231, 1);
}

.dynamics-right{
	width: calc(422 / 1440 * 100vw);
	/*height: 450px;*/
	margin-left: calc(40 / 1440 * 100vw);
	margin-top: 113px;
	position: relative;
}

@media screen and (max-width: 923px){
	.dynamics-fix{
		grid-template-columns: repeat(1, 1fr);
	}

	.dynamics-left-fix{
		justify-self: center;
		width: 100%;
	}

	.dynamics-right-fix{
		margin-top: 50px;
		margin-left: 0;
		justify-self: center;
		width: 100%;
	}
}

.dynamics-right-history-item{
	display: flex;
	flex-direction: row;
	margin-bottom: 37px;
	width: 100%;
	height: 59px;
	cursor: pointer;
	overflow: hidden;
}

.dynamics-right-history-item-monthday{
	font-size: 22rem;
	font-weight: 400;
	line-height: 18px;
}

.dynamics-right-history-item-year{
	font-size: 12rem;
	font-weight: 400;
	line-height: 18px;
}

.dynamics-right-history-item-datetime-container{
	text-align: right;
}

.dynamics-right-seperator{
	height: 100%;
	margin-left: 26px;
}

.dynamics-right-history-item-titledesc-container{
	display: flex; 
	flex-direction: column; 
	margin-left: 25px;
}

.dynamics-right-history-item-title{
	font-size: 14rem;
	font-weight: 500;
	line-height: 19px;
}

.dynamics-right-history-item-desc{
	font-size: 12rem;
	font-weight: 400;
	line-height: 18px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dynamics-right-history-item:hover div{
	color: rgba(7, 126, 231, 1);
}


</style>
