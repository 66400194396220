<template>
	<div class="flex-left banner" :style="{'--bg-image': bannerBackground}">
		<div class="titleBlock">
			<div class="title13 title13-fix">
				<div class="xyz-title title title-fix">{{title}}</div>
				<div class="xyz-title title3 title3-fix">{{title3}}</div>
			</div>
			<div :class="'xyz-title1 title1 title1-fix' + (name==undefined?'':' product-' + name + '-title1')">{{title1}}</div>
			<slot name="bannerButton"></slot>
		</div>
	</div>
</template>

<script type="text/javascript">

export default{
	props: [
		'name',
		'bannerBackground',
		'title',
		'title1',
		'title3'
	]
}
	
</script>

<style type="text/css" scoped>

.titleBlock{
	margin-left: 23%;
}

.title{
	font-size: 38rem;
	line-height: 53px;
	letter-spacing: 1px;
	margin-right: 20px;
}

.title1{
	font-size: 20rem;
	margin-top: 25px;
}

.product-xiaobaosouti-title1{
	letter-spacing: 9px;
}

.product-douzhuanyuyin-title1{
	letter-spacing: 14px;
}
.title3{
	font-size: 25rem;
	margin-left: 10px;
	display: flex;
	align-items: center;
}


.title13{
	display: flex;
	flex-direction: row;
}

@media screen and (max-width: 505px){
	.title-fix{
		margin-top: 50px;
	}

	.title13-fix{
		flex-direction: column;
	}

	.title3-fix{
		margin-left: 0;
	}

	.title1-fix{
		font-size: 15rem;
	}
}

@media screen and (max-width: 430px){
	.title1-fix{
		font-size: 12rem;
	}
}

@media screen and (max-width: 381px){
	.title1-fix{
		font-size: 10rem;
	}
}
</style>






















