<template>
	<div class="base">
		<banner :bannerBackground="consultList.banner" :title="consultList.title" :title1="consultList.title1" :title3="consultList.title3">
		</banner>

		<div class="content-container">
			<div class="content-inner-container">
				<div class="breadcrumb-container">
					<el-breadcrumb separator=">" class="breadcrumb">
						<el-breadcrumb-item :to="{path: '/consult'}" class="breadcrumb-item-consult">资讯</el-breadcrumb-item>
						<el-breadcrumb-item class="breadcrumb-item-current">{{dataList.breadTitle}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<div class="content">
					<div class="xyz-title content-title">
						{{dataList.title}}
					</div>

					<div class="content-datetime-container">
						<div class="content-datetime xyz-desc">
							{{dataList.timePrefix}} {{dataList.date}} {{dataList.time}}
						</div>

						<div class="content-comefrom xyz-desc">
							{{dataList.comeFrom}}
						</div>
					</div>

					<div class="xyz-seperate content-seperate"></div>

					<div class="content-paragraph" v-for="(value, index) in dataList.text" :key="index">
						<span v-if="value.type=='txt'" class="xyz-desc content-paragraph-text">
							{{value.text}}
						</span>

						<span v-if="value.type=='image'">
							<el-image :src="value.url">
								
							</el-image>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

import banner from "@/components/BannerComponent.vue"
import "@/css/Banner.css"
import axios from 'axios'

export default{
	components: {
		banner
	},

	created(){
		this.$emit('changeTabs', 'consult');
		this.id = this.$route.query.id;

		axios.get("/json/consult/" + this.id + ".json")
		.then(res => {
			this.dataList = res.data;
			window.scrollTo({
				top: 0
			})
		})

		axios.get("/json/consult.json")
		.then(res => {
			this.consultList = res.data;
			window.scrollTo({
				top: 0
			})
		})
	},

	data(){
		return {
			dataList: {},
			consultList: {},
			id: ""
		}
	}
}
	
</script>

<style type="text/css" scoped>

.breadcrumb{
	display: flex;
	align-items: center;
}

.breadcrumb-container{
	width: 100%;
	text-align: left;
}

.breadcrumb-item-consult /deep/ .el-breadcrumb__inner{
	font-size: 12rem;
	font-weight: 400;
	color: rgba(7, 21, 33, 1)
}

.breadcrumb-item-current:last-child ::v-deep .el-breadcrumb__inner{
	font-size: 12rem;
	font-weight: 400;
	color: rgba(33, 160, 252, 1);
	display: flex;
	align-items: center;
}

.content-container{
	margin-top: 26px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content-inner-container{
	width: calc(900 / 1440 * 100%);
}

@media screen and (max-width: 720px){
	.content-inner-container{
		width: 80%;
	}
}

.content{
	width: 100%;
	padding-top: calc(42 / 1440 * 100vw);
	padding-left: calc(45 / 1440 * 100vw);
	padding-right: calc(45 / 1440 * 100vw);
	padding-bottom: calc(58 / 1440 * 100vw);
	margin-top: 25px;
	margin-bottom: 64px;
	box-sizing: border-box;
	box-shadow: 0px 3px 50px 0px rgba(199,211,243,0.34);
}

.content-title{
	font-size: 18rem;
}

.content-datetime{
	font-size: 10rem;
}

.content-comefrom{
	font-size: 10rem;
	margin-left: 44px;
}

.content-datetime-container{
	display: flex;
	flex-direction: row;
	margin-top: 9px;
}

.content-seperate{
	width: 100%;
	margin-top: 27px;
	margin-bottom: 23px;
}

.content-paragraph{
	margin-bottom: 27px;

}

.content-paragraph-text{
	line-height: 26px;
	font-size: 14rem;
}

	
</style>